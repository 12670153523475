import React from "react";
import Carousel1 from "../Carousel1";

import ProductHead from "./ProductHead";
import "../../custome.css";
import ContactForm from "../ContactForm";
import CHICKEN1 from "../../images/CHICKEN1.jpg";
import CHICKEN2 from "../../images/CHICKEN2.jpg";
import CHICKEN3 from "../../images/CHICKEN3.jpg";
import chicken from "../../images/ChickenProduct1.jpg";

const slides = [CHICKEN1, CHICKEN2, CHICKEN3];
const Specifications = [
  {
    Title: "Rich in Nutrients",
    Value: "Protein, Vitamin B12, Phosphorus",
  },
  { Title: "Origin", Value: "Maharashtra India" },
  { Title: "Storage", Value: "-15°C or lower" },
  { Title: "InCo Terms", Value: "EXW, FOB, CIF, DDP" },
  { Title: "Inspection", Value: "SGS-QSS or as per requirement" },
  { Title: "Season Availability", Value: "Throughout the year" },
];
const FrozenChickenProduct = () => {
  return (
    <>
      <ProductHead image={chicken} text="Frozen Chicken" />
      <div className="p-6 max-w-6xl mx-auto">
        <h1 className="text-3xl font-bold mb-4 text-gray-700 bg-gray-lines">
          Frozen Chicken
        </h1>
        <p className="text-gray-700 mb-2 text-justify">
          We deal in high-quality frozen chicken. Our products undergo rigorous
          quality control to ensure they meet the highest standards of freshness
          and nutrition. We source our chicken from trusted farms, where they
          are raised with care and fed a balanced diet to enhance their
          nutritional value. Our state-of-the-art freezing process locks in
          essential nutrients and flavor, providing a product that is not only
          safe and hygienic but also rich in protein and other vital nutrients.
          Committed to excellence, we deliver frozen chicken that meets global
          quality standards, ensuring that our customers receive the very best
          in taste and nutrition. Today we remain singularly focused on
          providing carefully raised, fresh, local chicken to the India & Many
          other countries. We trace our roots back to a single, small egg farm.
          All of our chickens are raised in a reduced stress environment.
        </p>
        <div className="flex justify-center w-full my-4 bg-black">
          <Carousel1 autoSlide={true}>
            {slides.map((s, index) => (
              <img
                key={index}
                className="w-full flex-shrink-0"
                src={s}
                alt={`Slide ${index + 1}`}
              />
            ))}
          </Carousel1>
        </div>
        <p className="text-gray-700 mb-6 text-justify">
          <h2 className="text-xl font-semibold mb-4 ">Halal Chicken :</h2>
          We follow strict Halal processes, with all our chickens
          hand-slaughtered according to Islamic guidelines. We are actively
          seeking serious buyers interested in establishing long-term business
          relationships, ensuring mutual growth and success.{" "}
        </p>
        <div className="mt-8">
          <h2 className="text-xl font-semibold mb-4">
            Specifications of Eggs :
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-1">
            {Specifications.map((spec, index) => (
              <div key={index} className="p-1 border rounded">
                <div className="text-sm font-semibold py-2  bg-gray-800 text-center text-white">
                  {spec.Title}
                </div>
                <div className="text-gray-800 text-center">{spec.Value}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-8">
          <div className="text-sm font-semibold mb-2 text-blue-500">
            Packing
          </div>
          <div className="text-gray-800 mb-4">
            OEM custom labeling and customer preferred packing is now available
          </div>

          <div className="text-sm font-semibold mb-2 text-blue-500">
            Port of Loading
          </div>
          <div className="text-gray-800 mb-4">
            {" "}
            JNPT Port, Tuticorin/Chennai sea port
          </div>

          <div className="text-sm font-semibold mb-2 text-blue-500">
            Features
          </div>
          <div className="text-gray-800 mb-4">
            Accurate cuts
            <br />
            Well-cleaned and fresh
            <br />
            No bruises
            <br />
            No ammonia burns
            <br />
            No unpleasant odors
            <br />
            No excessive blood or blood stains
            <br />
            Moisture content is less than 3%
            <br />
            Feather off
          </div>

          <div className="text-sm font-semibold mb-2 text-blue-500">
            Quality tests{" "}
          </div>
          <div className="text-gray-800 mb-4">
            {" "}
            Tested for bird flu, salmonella inflection and other contamination
          </div>

          <div className="text-sm font-semibold mb-2 text-blue-500">
            Inspection on weight and quality (As per buyer’s requirement) :
          </div>
          <div className="text-gray-800 mb-4">
            SGS/ Any other third party Inspection Company
          </div>

          <div className="text-sm font-semibold mb-2 text-blue-500">
            Certificate :{" "}
          </div>
          <div className="text-gray-800 mb-4">
            Certificate of Origin
            <br />
            Fumigation Certificate
            <br />
            Phytosanitary Certificate
            <br />
            Certificate of Conformity (Country specific)
            <br />
            SGS Quality & Quantity Certificate
            <br />
            Other third party inspection agency certificate as per buyer
            specific requirement
          </div>
        </div>
      </div>
      <ContactForm />
    </>
  );
};

export default FrozenChickenProduct;
