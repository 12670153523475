import React from 'react';

const WhyUs = () => {
  const features = [
    {
      icon: '🎖️', // You can replace this with actual icons
      title: 'Product Quality',
      description: 'Quality in every aspect such as dimensional accuracy, designs, printing, labelling, and packaging.',
    },
    {
      icon: '💰',
      title: 'Competitive Price',
      description: 'We strive to offer our clients the best quality products at industry competitive prices.',
    },
    {
      icon: '📦',
      title: 'Packaging',
      description: 'We use superior quality packaging material and equipment to ensure the safety of the products in transit and delivery.',
    },
    {
      icon: '⏰',
      title: 'Timely Delivery',
      description: 'We have adopted innovative methods and procedures to ensure delivery of the order within a stipulated time frame.',
    },
    {
      icon: '🤝',
      title: 'Strong Vendor Base',
      description: 'We select our vendors on the basis of quality, production capacity, financial stability, and more.',
    },
    {
      icon: '🔄',
      title: 'Flexibility',
      description: 'We possess the ability to develop products as per buyers’ specifications and offer easy payment modes.',
    },
    {
      icon: '👥',
      title: 'Personalised Services',
      description: 'We offer highly personalised services to our clients and keep them informed of the movement of goods.',
    },
    {
      icon: '👍',
      title: 'Client Satisfaction',
      description: 'We understand the needs and demands of our clients and offer viable solutions for the same.',
    },
  ];

  return (
    <div className="py-12 flex justify-center">
      <div className="lg:max-w px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="uppercase text-2xl sm:text-3xl md:text-4xl font-extrabold text-gray-500">Why Us</h2>
        <p className="mt-4 text-sm sm:text-base md:text-xl  text-gray-500">There are some factors that have made us a globally renowned merchant exporter:</p>
        <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="text-center">
              <div className="flex justify-center items-center w-20 h-20 mx-auto rounded-full bg-blue-100 mb-4 text-4xl text-blue-500">
                {feature.icon}
              </div>
              <h3 className="text-sm sm:text-base md:text-xl  font-semibold text-gray-500">{feature.title}</h3>
              <p className="mt-2 text-gray-500">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
