import Carousel1 from "./Carousel1";
import WhyUs from "./Whyus";
import ship from "../images/home slider images/ship1.jpg";
import egg from "../images/EggProduct2.jpg";
import Freight from "../images/home slider images/Freight3.jpg";
import banana from "../images/home slider images/banana1.png";
import vermi from "../images/home slider images/vermi1.jpg";
import frozen from "../images/home slider images/frozen1.png";
import { COMPANY_NAME } from "../config";

const slides = [ship, banana, egg, vermi, frozen, Freight];

const Body = () => {
  return (
    <>
      <div className="flex justify-center w-full bg-black">
        <Carousel1 autoSlide={true}>
          {slides.map((s, index) => (
            <img
              key={index}
              className="w-full flex-shrink-0"
              src={s}
              alt={`Slide ${index + 1}`}
            />
          ))}
        </Carousel1>
      </div>
      <div className="w-full p-5 text-gray-500">
        <div className="font-extrabold p-5 uppercase text-2xl sm:text-3xl md:text-4xl flex flex-col justify-center items-center">
          Welcome to {COMPANY_NAME}
        </div>
        <p className="text-sm sm:text-base md:text-xl p-5 m-3 leading-7 sm:leading-8 md:leading-10 text-justify">
          At Seablink Overseas, we pride ourselves on being a trusted partner in
          global trade, specializing in the export of high-quality dehydrated
          banana powder, premium vermicompost, farm-fresh chicken eggs, and
          frozen chicken. With a commitment to excellence, we ensure that every
          product meets the highest industry standards, offering our clients the
          reliability and quality they need to thrive in their markets. Our
          dedication to sustainability, rigorous quality control, and
          transparent business practices make us the preferred choice for
          importers worldwide, helping you source with confidence and build
          lasting relationships.
          <br />
          <br />
          As the name suggests, Seablink Overseas is all about creating strong
          links across the seas. We are dedicated to linking India's finest
          products with markets across the globe. Our mission is to bridge the
          gap between quality and demand, ensuring that the best of India
          reaches every corner of the world. With a commitment to excellence and
          a passion for innovation, Seablink Overseas is your trusted partner in
          connecting local expertise with global opportunities.
        </p>
        <WhyUs />

        <div className="font-extrabold p-5 uppercase text-2xl sm:text-3xl md:text-4xl flex flex-col justify-center items-center">
          Services
        </div>
        <div className="p-6 w-full flex flex-col md:flex-row justify-center items-center md:justify-evenly">
          <div className="w-full md:w-3/12 border-4 border-double border-gray-600 h-auto md:h-96 flex flex-col justify-center items-center text-center m-2 p-5">
            <div className="text-xl sm:text-2xl font-bold">Export</div>
            <p className="leading-relaxed text-justify text-sm sm:text-base m-2">
              We are a trusted supplier of agriculture commodities from India.
              Our product range, direct buying from farmers as well as big
              markets, good quality, and competitive prices have made us one of
              the preferred exporter companies for our buyers. We are currently
              exporting vegetables (Banana powder, Poultry, Organic Fertilizers,
              Eggs).
            </p>
          </div>
          <div className="w-full md:w-3/12 border-4 border-double border-gray-600 h-auto md:h-96 flex flex-col justify-center items-center text-center m-2 p-5">
            <div className="text-xl sm:text-2xl font-bold leading-relaxed">
              Import
            </div>
            <p className="leading-relaxed text-justify text-sm sm:text-base m-2">
              We are importing from few countries. Our partner handles all kind
              of sourcing with the required quality, quantity, and logistics. As
              we are based in India both we can handle your consignments very
              efficiently and economically Please let us know your requirements.
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </p>
          </div>
          <div className="w-full md:w-3/12 border-4 border-double border-gray-600 h-auto md:h-96 flex flex-col justify-center items-center text-center m-2 p-5">
            <div className="text-xl sm:text-2xl font-bold leading-loose">
              Logistic
            </div>
            <p className="leading-relaxed text-justify text-sm sm:text-base m-2">
              In the realm of import-export, logistics plays a crucial role in
              ensuring the seamless movement of goods across international
              borders. It involves the careful planning, execution, and
              management of transportation. Efficient logistics ensures that
              products reach their destinations on time, minimizing delays and
              costs, and enhancing overall customer satisfaction.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
