import React from "react";
import banana from "../../images/home slider images/vermi1.jpg";
import Carousel1 from "../Carousel1";

import ProductHead from "./ProductHead";
import "../../custome.css";
import ContactForm from "../ContactForm";
import VermiCompost1 from "../../images/VermiCompost1.jpg";
import VermiCompost2 from "../../images/VermiCompost2.jpg";
import VermiCompost3 from "../../images/VermiCompost3.jpg";
import VermiCompost4 from "../../images/VermiCompost4.jpg";
import VermiCompost5 from "../../images/VermiCompost5.jpg";
const slides = [
  VermiCompost5,
  VermiCompost1,
  VermiCompost3,
  VermiCompost2,
  VermiCompost4,
];

const VermiCompostProduct = () => {
  return (
    <>
      <ProductHead image={banana} text="Vermi Compost" />
      {/* <div className="p-6 max-w-6xl mx-auto"> */}
      <div className="p-6 max-w-6xl mx-auto">
        <h1 className="text-3xl font-bold mb-4 text-gray-700 bg-gray-lines">
          Vermi Compost
        </h1>
        <p className="text-gray-700 mb-2 text-justify">
          Elevate your agricultural operations with our premium vermicompost,
          Sourced from India's fertile lands, our organic fertilizer is packed
          with essential nutrients to revitalize your plants and promote healthy
          growth. Enhance your soil's vitality, reduce waste, and support
          sustainable farming practices. Our bulk quantities ensure consistent
          supply and cost-effective solutions. Experience the transformative
          power of organic fertilization and unlock the full potential of your
          crops. Contact us today to discuss your bulk requirements and receive
          a personalized quote.
        </p>{" "}
        <div className="flex justify-center w-full my-4 bg-black">
          <Carousel1 autoSlide={true}>
            {slides.map((s, index) => (
              <img
                key={index}
                className="w-full flex-shrink-0"
                src={s}
                alt={`Slide ${index + 1}`}
              />
            ))}
          </Carousel1>
        </div>
        <div className="mt-8">
          <h2 className="text-xl font-semibold mb-4">
            Specifications of Vermi Compost
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-1">
            <div className="p-1 border rounded">
              <div className="text-sm font-semibold py-2 bg-gray-800 text-center text-white">
                Features
              </div>
              <div className="text-gray-800 text-center">
                Organic, Free from toxins
              </div>
            </div>
            <div className="p-1 border rounded">
              <div className="text-sm font-semibold text-center py-2 bg-gray-800 text-white">
                Origin
              </div>
              <div className="text-gray-800 text-center ">
                Maharashtra, India
              </div>
            </div>
            <div className="p-1 border rounded">
              <div className="text-sm font-semibold text-center py-2 bg-gray-800 text-white">
                Season Availability
              </div>
              <div className="text-gray-800 text-center">
                Throughout the year
              </div>
            </div>
            <div className="p-1 border rounded">
              <div className="text-sm font-semibold text-center py-2 bg-gray-800 text-white">
                Applications
              </div>
              <div className="text-gray-800 text-center">
                Organic farming, Gardening
              </div>
            </div>
            <div className="p-1 border rounded">
              <div className="text-sm font-semibold text-center py-2 bg-gray-800 text-white">
                InCo Terms
              </div>
              <div className="text-gray-800 text-center">
                {" "}
                EXW, FOB, CIF, DDP
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8">
          <div className="text-sm font-semibold mb-2 text-blue-500">
            Packing
          </div>
          <div className="text-gray-800 mb-4">
            5, 10, 25, 50 kg Bags or as per requirement
          </div>
          <div className="text-sm font-semibold mb-2 text-blue-500">
            Port of Loading
          </div>
          <div className="text-gray-800 mb-4">
            {" "}
            JNPT Port, Tuticorin/Chennai sea port
          </div>
          <div className="text-sm font-semibold mb-2 text-blue-500">
            Inspection on weight and quality (As per buyer’s requirement) :
          </div>
          <div className="text-gray-800 mb-4">
            SGS/ Any other third party Inspection Company
          </div>

          <div className="text-sm font-semibold mb-2 text-blue-500">
            Certificate :{" "}
          </div>
          <div className="text-gray-800 mb-4">
            Certificate of Origin
            <br />
            Fumigation Certificate
            <br />
            Phytosanitary Certificate
            <br />
            Certificate of Conformity (Country specific)
            <br />
            SGS Quality & Quantity Certificate
            <br />
            Other third party inspection agency certificate as per buyer
            specific requirement
          </div>
        </div>
      </div>
      <ContactForm />
    </>
  );
};

export default VermiCompostProduct;
