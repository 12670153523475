import React from "react";
import Carousel1 from "../Carousel1";

import ProductHead from "./ProductHead";
import "../../custome.css";
import ContactForm from "../ContactForm";
import EGGS1 from "../../images/EGGS1.jpg";
import EGGS2 from "../../images/EGGS2.jpg";
import EGGS3 from "../../images/EGGS3.jpg";
import egg from "../../images/EggProduct1.jpg";

const slides = [EGGS1, EGGS2, EGGS3];
const Specifications = [
  { Title: "Color", Value: "Chalky white /Medium Brown" },
  {
    Title: "Rich in Nutrients",
    Value: "Protein, Vitamin B12, Vitamin D, Choline",
  },
  { Title: "Origin", Value: "Maharashtra India" },
  { Title: "Storage", Value: "10° to 13° C" },
  { Title: "Weight", Value: "50 - 55 gm" },
  { Title: "InCo Terms", Value: "EXW, FOB, CIF, DDP" },
  { Title: "Inspection", Value: "SGS-QSS or as per requirement" },
  { Title: "Season Availability", Value: "Throughout the year" },
];
const EggsProduct = () => {
  return (
    <>
      <ProductHead image={egg} text="Eggs" />
      <div className="p-6 max-w-6xl mx-auto">
        <h1 className="text-3xl font-bold mb-4 text-gray-700 bg-gray-lines">
          Eggs
        </h1>
        <p className="text-gray-700 mb-2 text-justify">
          We are engaged in supplying and exporting a premium range of chicken
          eggs, available in various varieties, sizes, and weights. Our eggs,
          both white and brown shell, possess thick albumen with dark yellow or
          orange yolks, ensuring high quality. They are rigorously tested for
          bird flu, salmonella infection, and other contaminants, making them
          completely safe and certified by the Department of Animal Husbandry.
          Each egg is all-natural, packed with 13 essential vitamins and
          minerals, high-quality protein, unsaturated fats, and antioxidants,
          all for just 70 calories. Our eggs are available in small, medium, and
          large sizes, and are 100% safe for human consumption. We export fresh
          eggs with a capacity of 5 to 10 containers per week, offering the best
          quality at competitive prices. These eggs are packed in superior
          quality, attractive carton boxes, ensuring they are graded, cleaned,
          stamped with a quality seal, and packed using stringent standards for
          prompt delivery.
        </p>
        <div className="flex justify-center w-full my-4 bg-black">
          <Carousel1 autoSlide={true}>
            {slides.map((s, index) => (
              <img
                key={index}
                className="w-full flex-shrink-0"
                src={s}
                alt={`Slide ${index + 1}`}
              />
            ))}
          </Carousel1>
        </div>

        <div className="mt-8">
          <h2 className="text-xl font-semibold mb-4">
            Specifications of Eggs :
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-1">
            {Specifications.map((spec, index) => (
              <div key={index} className="p-1 border rounded">
                <div className="text-sm font-semibold py-2  bg-gray-800 text-center text-white">
                  {spec.Title}
                </div>
                <div className="text-gray-800 text-center">{spec.Value}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-8">
          <div className="text-sm font-semibold mb-2 text-blue-500">
            Packing
          </div>
          <div className="text-gray-800 mb-4">
            30 eggs laid on paper pulp moulded trays.
            <br />
            12 trays packed in 5 ply strong export cartons.
            <br />
            360 eggs per carton.{" "}
          </div>
          <div className="text-sm font-semibold mb-2 text-blue-500">
            Port of Loading
          </div>
          <div className="text-gray-800 mb-4">
            {" "}
            JNPT Port, Tuticorin/Chennai sea port
          </div>

          <div className="text-sm font-semibold mb-2 text-blue-500">
            Features
          </div>
          <div className="text-gray-800 mb-4">
            {" "}
            Thick and smooth shell
            <br />
            Finely graded, sorted, and cleaned.
          </div>

          <div className="text-sm font-semibold mb-2 text-blue-500">
            Quality tests{" "}
          </div>
          <div className="text-gray-800 mb-4">
            {" "}
            Tested for bird flu, salmonella inflection and other contamination
          </div>

          <div className="text-sm font-semibold mb-2 text-blue-500">
            Shipping/Loading
          </div>
          <div className="text-gray-800 mb-4">
            {" "}
            1312 cartons in 40 feet high cube reefer container.
            <br />
            472,320 eggs per 40 ft high cube reefer container.
          </div>
          <div className="text-sm font-semibold mb-2 text-blue-500">
            Inspection on weight and quality (As per buyer’s requirement) :
          </div>
          <div className="text-gray-800 mb-4">
            SGS/ Any other third party Inspection Company
          </div>

          <div className="text-sm font-semibold mb-2 text-blue-500">
            Certificate :{" "}
          </div>
          <div className="text-gray-800 mb-4">
            Certificate of Origin
            <br />
            Fumigation Certificate
            <br />
            Phytosanitary Certificate
            <br />
            Certificate of Conformity (Country specific)
            <br />
            SGS Quality & Quantity Certificate
            <br />
            Other third party inspection agency certificate as per buyer
            specific requirement
          </div>
        </div>
      </div>
      <ContactForm />
    </>
  );
};

export default EggsProduct;
