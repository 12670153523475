import React, { useState } from "react";
import { ProductList } from "../config";
import { BACKEND_URL } from "../config";

const ContactForm = ({ image, text }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    companyName: "",
    mobileNumber: "",
    message: "",
    country: "",
    product: "",
  });

  const [errors, setErrors] = useState({});
  const validateForm = () => {
    const newErrors = {};
    if (!formData.fullName.trim()) newErrors.fullName = "Full Name is required";
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }
    if (!formData.country.trim())
      newErrors.country = "Country name is required";
    if (!formData.product.trim())
      newErrors.product = "Product name is required";

    if (!formData.companyName.trim())
      newErrors.companyName = "Company Name is required";
    if (!formData.mobileNumber.trim()) {
      newErrors.mobileNumber = "Mobile Number is required";
    } else if (!/^\+\d+$/.test(formData.mobileNumber)) {
      newErrors.mobileNumber =
        "Mobile Number is invalid (must include country code)";
    }
    if (!formData.message.trim()) newErrors.message = "Message is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    try {
      const response = await fetch(`${BACKEND_URL}/submitForm`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          formData,
        }),
      });

      if (response.ok) {
        setFormData({
          fullName: "",
          email: "",
          companyName: "",
          mobileNumber: "",
          message: "",
          country: "",
          product: "",
        });
        alert("Message sent successfully!");
      } else {
        alert(
          "Failed to send message. Please try sending email to given email address."
        );
      }
    } catch (error) {
      console.error(
        "Error submitting form:",
        error,
        " Please try sending email to given email address."
      );
      alert(
        "An error occurred. Please try sending email to given email address."
      );
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="w-full mx-auto bg-gray-100">
      <div className="flex justify-center items-center pt-5">
        <div className=" sm:w-full lg:w-3/4">
          <div className="text-2xl  md:text-3xl mb-3 text-center ">
           Interested? Request a Sample or Make an Enquiry Now!
          </div>
          <form className="w-full p-5" onSubmit={handleSubmit}>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-2 mb-6 md:mb-0">
                {/* <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-full-name">
                  Full Name
                </label> */}
                <input
                  className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                    errors.fullName ? "border-red-500" : "border-gray-200"
                  } rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white`}
                  id="grid-full-name"
                  placeholder="Full Name"
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                />
                {errors.fullName && (
                  <p className="text-red-500 text-xs italic">
                    {errors.fullName}
                  </p>
                )}
              </div>
              <div className="w-full md:w-1/2 px-2">
                {/* <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-email">
                  Email
                </label> */}
                <input
                  className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                    errors.email ? "border-red-500" : "border-gray-200"
                  } rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white`}
                  id="grid-email"
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <p className="text-red-500 text-xs italic">{errors.email}</p>
                )}
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-2 mb-6 md:mb-0">
                {/* <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-company-name">
                  Company Name
                </label> */}
                <input
                  className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                    errors.companyName ? "border-red-500" : "border-gray-200"
                  } rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white`}
                  id="grid-company-name"
                  type="text"
                  placeholder="Company Name"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                />
                {errors.companyName && (
                  <p className="text-red-500 text-xs italic">
                    {errors.companyName}
                  </p>
                )}
              </div>
              <div className="w-full md:w-1/2 px-2">
                {/* <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-mobile-number">
                  Mobile Number
                </label> */}
                <input
                  className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                    errors.mobileNumber ? "border-red-500" : "border-gray-200"
                  } rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white`}
                  id="grid-mobile-number"
                  type="text"
                  name="mobileNumber"
                  placeholder="Mobile Number"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                />
                {errors.mobileNumber && (
                  <p className="text-red-500 text-xs italic">
                    {errors.mobileNumber}
                  </p>
                )}
                <p className="text-gray-600 text-xs italic">
                  with country code
                </p>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-2 mb-6 md:mb-0">
                {/* <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-country">
                  Country
                </label> */}
                <input
                  className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                    errors.country ? "border-red-500" : "border-gray-200"
                  } rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white`}
                  id="grid-country"
                  type="text"
                  name="country"
                  placeholder="Country"
                  value={formData.country}
                  onChange={handleChange}
                />
                {errors.country && (
                  <p className="text-red-500 text-xs italic">
                    {errors.country}
                  </p>
                )}
              </div>
              <div className="w-full md:w-1/2 px-2">
                {/* <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-product">
                  Product
                </label> */}
                <select
                  className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                    errors.product ? "border-red-500" : "border-gray-200"
                  } rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white`}
                  id="grid-product"
                  name="product"
                  placeholder="Product"
                  value={formData.product}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  {ProductList.map((product) => (
                    <option key={product.title} value={product.title}>
                      {product.title}
                    </option>
                  ))}
                </select>
                {errors.product && (
                  <p className="text-red-500 text-xs italic">
                    {errors.product}
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-2">
                {/* <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-message">
                  Message
                </label> */}
                <textarea
                  id="grid-message"
                  name="message"
                  placeholder="Message"
                  className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                    errors.message ? "border-red-500" : "border-gray-200"
                  } rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white`}
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
                {errors.message && (
                  <p className="text-red-500 text-xs italic">
                    {errors.message}
                  </p>
                )}
                <p className="text-gray-600 text-xs italic">
                  Make it as long and as crazy as you'd like
                </p>
              </div>
            </div>
            <button
              type="submit"
              className="py-3 px-5 text-sm font-medium text-center rounded-lg bg-green-300 sm:w-fit hover:bg-green-400 focus:ring-4 focus:outline-none focus:ring-green-200"
            >
              Send message
            </button>
            {Object.keys(errors).length > 0 && (
              <p className="text-red-500 text-xs italic py-2">
                Errors in form. Please correct them.
              </p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
