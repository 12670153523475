
const productHead = ({ image, text }) => {
  return (
    <div>
      <div className="w-full h-64 sm:h-72 md:h-96 lg:h-[33rem] overflow-hidden">
        <img
          src={image}
          alt="Full Width Example"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="bg-blue-300 text-center p-4">
        Products / {text}
        </div>
    </div>
  );
};

export default productHead;
