import { useState } from "react";
import Title from "./Title";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

const Head = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <header className="sticky top-0 bg-white z-10 shadow-md">
      <div className="p-3 w-full m-0 md:m-auto md:w-4/5 flex items-center justify-between">
        <Title />
        <div className="flex items-center md:hidden">
          <button
            onClick={toggleMenu}
            className="text-gray-500 focus:outline-none"
          >
            <FontAwesomeIcon
              icon={menuOpen ? faTimes : faBars}
              className="text-5xl"
            />
          </button>
        </div>
        <ul
          className={`fixed flex flex-col top-20 left-0 w-full p-4 transform transition-transform ${
            menuOpen ? "translate-x-0 bg-blue-100" : "-translate-x-full"
          } md:flex md:flex-row md:gap-10 md:text-xl md:static md:w-auto md:transform-none`}
        >
          <Link to="/" onClick={() => setMenuOpen(false)}>
            <li className="px-3 py-2 text-gray-500 hover:text-green-400">
              Home
            </li>
          </Link>
          {/* <Link to="/about" onClick={() => setMenuOpen(false)}>
            <li className="px-3 py-2 text-gray-500 hover:text-green-400">
              About Us
            </li>
          </Link> */}

          <Link to="/products" onClick={() => setMenuOpen(false)}>
            <li className="px-3 py-2 text-gray-500 hover:text-green-400">
              All Products
            </li>
          </Link>
          <Link to="/certificates" onClick={() => setMenuOpen(false)}>
            <li className="px-3 py-2 text-gray-500 hover:text-green-400">
              Certificates
            </li>
          </Link>
          <Link to="/contact" onClick={() => setMenuOpen(false)}>
            <li className="px-3 py-2 text-gray-500 hover:text-green-400">
              Contact Us
            </li>
          </Link>
        </ul>
      </div>
    </header>
  );
};

export default Head;
