import React from "react";
import banana from "../../images/home slider images/banana1.png";
import Carousel1 from "../Carousel1";

import ProductHead from "./ProductHead";
//import custome.css
import "../../custome.css";
import ContactForm from "../ContactForm";
import banana1 from "../../images/BANANA11.jpg";
import banana2 from "../../images/BANANA12.jpg";
import banana3 from "../../images/BANANA13.jpg";
import banana4 from "../../images/BANANA14.jpg";

const slides = [banana1, banana2, banana3, banana4];
const BananaPowderProduct = () => {
  return (
    <>
      <ProductHead image={banana} text="Banana Powder" />
      {/* <div className="p-6 max-w-6xl mx-auto"> */}
      <div className="p-6 max-w-6xl mx-auto">
        <h1 className="text-3xl font-bold mb-4 text-gray-700 bg-gray-lines">
          Banana Powder
        </h1>
        <p className="text-gray-700 mb-2 text-justify">
          Experience the pure, natural goodness of our premium banana powder.
          Sourced from sun-kissed bananas, our product is packed with essential
          nutrients and a delightful tropical flavor. Whether you're crafting
          smoothies, baking, creating gluten-free recipes or adding a unique
          twist to your beverages, our banana powder is the perfect choice.
          Enjoy the convenience of a versatile ingredient that elevates your
          culinary creations while providing a healthy boost.
        </p>
        <div className="flex justify-center w-full my-4 bg-black">
          <Carousel1 autoSlide={true}>
            {slides.map((s, index) => (
              <img
                key={index}
                className="w-full flex-shrink-0"
                src={s}
                alt={`Slide ${index + 1}`}
              />
            ))}
          </Carousel1>
        </div>
        <p className="text-gray-700 mb-6 text-justify">
          <h2 className="text-xl font-semibold mb-4 ">Applications/Uses :</h2>
          Banana/plantain powder is a versatile ingredient with numerous
          culinary and cosmetic applications. In the kitchen, it can be used as
          a thickener, sweetener, or flavoring agent in smoothies, baked goods,
          and more. Its high potassium content makes it a beneficial addition to
          diets. In the beauty world, banana/plantain powder can be used as a
          natural exfoliant, mask, or even a foundation base. Its ability to
          absorb oil and provide a smooth, even complexion makes it a popular
          choice for those seeking natural beauty solutions.{" "}
        </p>
        <div className="mt-8">
          <h2 className="text-xl font-semibold mb-4">
            Specifications of Banana Powder :
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-1">
            <div className="p-1 border rounded">
              <div className="text-sm font-semibold py-2 bg-gray-800 text-center text-white">
                Color
              </div>
              <div className="text-gray-800 text-center">
                Raw: White / Ripe: Yellowish white
              </div>
            </div>
            <div className="p-1 border rounded">
              <div className="text-sm font-semibold text-center py-2 bg-gray-800 text-white">
                Rich in Nutrients
              </div>
              <div className="text-gray-800 text-center">
                {" "}
                Iron, Potassium, Protein, Fiber,Magnesium
              </div>
            </div>
            <div className="p-1 border rounded">
              <div className="text-sm font-semibold text-center py-2 bg-gray-800 text-white">
                Origin
              </div>
              <div className="text-gray-800 text-center">
                {" "}
                Maharashtra, Tamilnadu India
              </div>
            </div>
            <div className="p-1 border rounded">
              <div className="text-sm font-semibold text-center py-2 bg-gray-800 text-white">
                Storage
              </div>
              <div className="text-gray-800 text-center">
                Room temperature, Dry place
              </div>
            </div>
            <div className="p-1 border rounded">
              <div className="text-sm font-semibold  py-2 bg-gray-800 text-center text-white">
                Weight
              </div>
              <div className="text-gray-800 text-center">500gm & Above</div>
            </div>
            <div className="p-1 border rounded">
              <div className="text-sm font-semibold py-2 bg-gray-800 text-center text-white">
                Variety
              </div>
              <div className="text-gray-800 text-center">Ripe and Unripe</div>
            </div>
            <div className="p-1 border rounded">
              <div className="text-sm font-semibold text-center py-2 bg-gray-800 text-white">
                Self Life
              </div>
              <div className="text-gray-800 text-center ">
                180 days from date of packing
              </div>
            </div>
            <div className="p-1 border rounded">
              <div className="text-sm font-semibold text-center py-2 bg-gray-800 text-white">
                Unit Price
              </div>
              <div className="text-gray-800 text-center">Per Kg</div>
            </div>
            <div className="p-1 border rounded">
              <div className="text-sm font-semibold text-center py-2 bg-gray-800 text-white">
                InCo Terms
              </div>
              <div className="text-gray-800 text-center">
                {" "}
                EXW, FOB, CIF, DDP
              </div>
            </div>
            <div className="p-1 border rounded">
              <div className="text-sm font-semibold text-center py-2 bg-gray-800 text-white">
                Inspection
              </div>
              <div className="text-gray-800 text-center">
                {" "}
                SGS-QSS or as per requirement
              </div>
            </div>
            <div className="p-1 border rounded">
              <div className="text-sm font-semibold text-center py-2 bg-gray-800 text-white">
                Season Availability
              </div>
              <div className="text-gray-800 text-center">
                Throughout the year
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8">
          <div className="text-sm font-semibold mb-2 text-blue-500">
            Packing
          </div>
          <div className="text-gray-800 mb-4">
            1, 5, 10, 25, 50 kg bags, air tight containers or as per requirement
          </div>
          <div className="text-sm font-semibold mb-2 text-blue-500">
            Port of Loading
          </div>
          <div className="text-gray-800 mb-4">
            {" "}
            JNPT Port, Tuticorin/Chennai sea port
          </div>
          <div className="text-sm font-semibold mb-2 text-blue-500">
            Inspection on weight and quality (As per buyer’s requirement) :
          </div>
          <div className="text-gray-800 mb-4">
            SGS/ Any other third party Inspection Company
          </div>

          <div className="text-sm font-semibold mb-2 text-blue-500">
            Certificate :{" "}
          </div>
          <div className="text-gray-800 mb-4">
            Certificate of Origin
            <br />
            Fumigation Certificate
            <br />
            Phytosanitary Certificate
            <br />
            Certificate of Conformity (Country specific)
            <br />
            SGS Quality & Quantity Certificate
            <br />
            Other third party inspection agency certificate as per buyer
            specific requirement
          </div>
        </div>
      </div>
      <ContactForm />
    </>
  );
};

export default BananaPowderProduct;
