import image from "../images/LogoWhite.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COMPANY_NAME, ProductList,COMPANY_EMAIL } from "../config";

import {
  faLocationDot,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import Certificates from "./Certificates";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white w-full">
      <div className="flex flex-wrap justify-center md:justify-between py-6 px-4 md:px-8">
        <div className="w-full md:w-1/4 flex flex-col items-center md:items-start py-4">
          <img className="w-40 md:w-56" alt="logo" src={image} />
        </div>
        <div className="w-full md:w-1/4 flex flex-col items-center md:items-start py-4">
          <div className="text-2xl font-bold mb-4">Information</div>
          <Link to="/" className="block mt-2 hover:underline">
            Home
          </Link>
          <Link to="/contact" className="block mt-2 hover:underline">
            Contact Us
          </Link>
          <Link to="/certificates" className="block mt-2 hover:underline">
            Certificates
          </Link>
          <Link to="/products" className="block mt-2 hover:underline">
            All Products
          </Link>
        </div>
        <div className="w-full md:w-1/4 flex flex-col items-center md:items-start py-4">
          <div className="text-2xl font-bold mb-4">Products Range</div>
          {ProductList.map((product) => (
            <div className="mt-2" key={product}>
              <Link to={product.productRoute}>{product.title}</Link>
            </div>
          ))}
        </div>
        <div className="w-full md:w-1/4 flex flex-col items-center md:items-start py-4">
          <div className="text-2xl font-bold mb-4">Contact Us</div>
          <div className="flex items-start mb-4">
            <FontAwesomeIcon icon={faLocationDot} className="text-xl mr-2" />
            <div className="text-sm">
              Shop 103 Adiamville soc <br />
              Gaikwad nagar, Punawale, PUNE
              <br />
              411033 India 🇮🇳
            </div>
          </div>
          <div className="flex items-start mb-4">
            <FontAwesomeIcon icon={faEnvelope} className="text-xl mr-2" />
            <a
              href={`mailto:${COMPANY_EMAIL}`}
              className="text-blue-500 hover:underline text-sm"
            >
              {COMPANY_EMAIL}
            </a>
          </div>
          <div className="flex items-start mb-4">
            <FontAwesomeIcon icon={faPhone} className="text-xl mr-2" />
            <a
              href="tel:+918600663238"
              className="text-blue-500 hover:underline text-sm"
            >
              +91 8600663238
            </a>
          </div>
        </div>
      </div>
      <hr className="my-4 border-gray-700" />
      <Certificates styles="max-h-16 m-4" />
      <p className="text-sm text-center md:text-right px-5 py-4">
        © {COMPANY_NAME}. All Rights Reserved. Designed and developed By{" "}
        <a className="font-bold text-blue-300">Pratik Jadhav</a>{" "}
        <a
          href="https://www.linkedin.com/in/dev-pratik-jadhav/"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block ml-2"
        >
          <FontAwesomeIcon
            icon={faLinkedin}
            className="text-blue-500 text-xl"
          />
        </a>
        <a
          href="https://github.com/dev-pratikj"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block ml-2"
        >
          <FontAwesomeIcon icon={faGithub} className="text-blue-500 text-xl" />
        </a>
      </p>
    </footer>
  );
};

export default Footer;
