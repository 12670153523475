import apeda from "../images/APEDA1.png";
import fssi from "../images/FSSI1.png";
import chemixil from "../images/CHEMIXIL1.png";
import dgft from "../images/DGFT1.png";
import indiaMart from "../images/INDIAMART1.png";
import spices from "../images/SPICEBOARD1.jpg";
import sgs from "../images/SGS1.png";

const Certificates = ({ styles }) => {
  return (
    <div className="flex flex-wrap justify-center items-center gap-4 p-4">
      <img
        className={`max-w-[6rem] sm:max-w-[8rem] md:max-w-[10rem] ${styles}`}
        src={apeda}
        alt="APEDA"
      />
      <img
        className={`max-w-[6rem] sm:max-w-[8rem] md:max-w-[10rem] ${styles}`}
        src={fssi}
        alt="FSSI"
      />
      <img
        className={`max-w-[6rem] sm:max-w-[8rem] md:max-w-[10rem] ${styles}`}
        src={chemixil}
        alt="CHEMIXIL"
      />
      <img
        className={`max-w-[6rem] sm:max-w-[8rem] md:max-w-[10rem] ${styles}`}
        src={dgft}
        alt="DGFT"
      />
      <img
        className={`max-w-[6rem] sm:max-w-[8rem] md:max-w-[10rem] ${styles}`}
        src={sgs}
        alt="Spices Board"
      />
      <img
        className={`max-w-[6rem] sm:max-w-[8rem] md:max-w-[10rem] ${styles}`}
        src={spices}
        alt="Spices Board"
      />
      <img
        className={`max-w-[6rem] sm:max-w-[8rem] md:max-w-[10rem] ${styles}`}
        src={indiaMart}
        alt="IndiaMart"
      />
    </div>
  );
};

export default Certificates;
