const Search = ({ imageClass, text }) => {
  return (
    <div className={`${imageClass} bg-cover p-8 md:p-11`}>
      <div className="w-full m-0 md:w-4/5 md:m-auto py-3 flex h-auto md:h-64 justify-evenly items-center flex-col md:flex-row">
        <h1 className="w-full text-center md:text-left pl-3 font-bold text-3xl md:text-5xl text-white">
          {text}
        </h1>
   
      </div>
    </div>
  );
};

export default Search;
