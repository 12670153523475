import { Link } from "react-router-dom";
import { SITE_LOGO } from "../config";
import image from "../images/LogoWhite.png";

const Title = () => (
  <Link to="/">
    <img
      className="w-20 min-w-[5rem] "
      alt="logo"
      style={{  width: "100px" }}
      // src={SITE_LOGO}
      src={image}
    />
  </Link>
);
export default Title;
