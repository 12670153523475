import React, { useState } from "react";
import SearchCopy from "./SearchCopy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faLocationDot,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import fb from "../images/fb.png";
import insta from "../images/insta.png";
import yt from "../images/yt.png";
import linkedin from "../images/linkedin.png";
import { ProductList, BACKEND_URL, COMPANY_EMAIL } from "../config";
const Contact = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    companyName: "",
    mobileNumber: "",
    message: "",
    country: "",
    product: "",
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!formData.fullName.trim()) newErrors.fullName = "Full Name is required";
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }
    if (!formData.country.trim())
      newErrors.country = "Country name is required";
    if (!formData.product.trim())
      newErrors.product = "Product name is required";
    if (!formData.companyName.trim())
      newErrors.companyName = "Company Name is required";
    if (!formData.mobileNumber.trim()) {
      newErrors.mobileNumber = "Mobile Number is required";
    } else if (!/^\+\d+$/.test(formData.mobileNumber)) {
      newErrors.mobileNumber =
        "Mobile Number is invalid (must include country code)";
    }
    if (!formData.message.trim()) newErrors.message = "Message is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    try {
      console.log("🚀 ~ handleSubmit ~ formData:", formData);
      const response = await fetch(`${BACKEND_URL}/submitForm`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          formData,
        }),
      });
      if (response.ok) {
        setFormData({
          fullName: "",
          email: "",
          companyName: "",
          mobileNumber: "",
          message: "",
          country: "",
          product: "",
        });
        alert("Message sent successfully!");
      } else {
        alert(
          "Failed to send message. Please try sending email to given email address."
        );
      }
    } catch (error) {
      console.error(
        "Error submitting form:",
        error,
        " Please try sending email to given email address."
      );
      alert(
        "An error occurred. Please try sending email to given email address."
      );
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  console.log(errors);
  return (
    <>
      <SearchCopy imageClass={"bg-search-bg"} text="Contact Us..." />
      <div className="py-10 px-4 md:px-20 flex flex-col md:flex-row md:justify-evenly">
        <div className="w-full md:w-1/2 lg:w-1/3 mb-8 md:mb-0">
          <div className="text-2xl md:text-3xl">REGISTERED ADDRESS:</div>
          <div className="flex mt-4">
            <div className=" text-xl md:text-2xl">
              <FontAwesomeIcon icon={faLocationDot} />
            </div>
            <div className="px-2">
              Shop 103 Adiamville soc <br />
              Gaikwad nagar, punawale, PUNE
              <br />
              411033 India
            </div>
          </div>
          <div className="flex mt-4">
            <div className="text-xl md:text-2xl">
              <FontAwesomeIcon icon={faEnvelope} />
            </div>{" "}
            <div className="px-2">
              <a
                href={`mailto:${COMPANY_EMAIL}`}
                className="text-blue-500 hover:underline"
              >
                {COMPANY_EMAIL}
              </a>
            </div>{" "}
          </div>
          <div className="flex mt-4">
            <div className=" text-xl md:text-2xl">
              <FontAwesomeIcon icon={faPhone} />
            </div>
            <div className="px-2">
              <a
                href="tel:+918600663238"
                className="text-blue-500 hover:underline"
              >
                +91 8600663238
              </a>
            </div>
          </div>
          <div className="py-10">
            <div className="text-2xl md:text-3xl">Social Media:</div>
            <p className="flex mt-4">
              <img
                className="max-h-12 md:max-h-16 mr-4"
                src={fb}
                alt="Facebook"
              />
              <img
                className="max-h-12 md:max-h-16 mr-4"
                src={insta}
                alt="Instagram"
              />
              <img
                className="max-h-12 md:max-h-16 mr-4"
                src={yt}
                alt="YouTube"
              />
              <img
                className="max-h-12 md:max-h-16"
                src={linkedin}
                alt="LinkedIn"
              />
            </p>
          </div>
        </div>
        <div className="w-full md:w-1/2 lg:w-1/3">
          <div className="text-2xl md:text-3xl">CONNECT WITH US:</div>

          <form className="py-5 w-full" onSubmit={handleSubmit}>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-full-name"
                >
                  Full Name
                </label>
                <input
                  className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                    errors.fullName ? "border-red-500" : "border-gray-200"
                  } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                  id="grid-full-name"
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                />
                {errors.fullName && (
                  <p className="text-red-500 text-xs italic">
                    {errors.fullName}
                  </p>
                )}
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-email"
                >
                  Email
                </label>
                <input
                  className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                    errors.email ? "border-red-500" : "border-gray-200"
                  } rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white`}
                  id="grid-email"
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <p className="text-red-500 text-xs italic">{errors.email}</p>
                )}
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-company-name"
                >
                  Company Name
                </label>
                <input
                  className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                    errors.companyName ? "border-red-500" : "border-gray-200"
                  } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                  id="grid-company-name"
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                />
                {errors.companyName && (
                  <p className="text-red-500 text-xs italic">
                    {errors.companyName}
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-mobile-number"
                >
                  Mobile Number
                </label>
                <input
                  className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                    errors.mobileNumber ? "border-red-500" : "border-gray-200"
                  } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                  id="grid-mobile-number"
                  type="text"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                />
                {errors.mobileNumber && (
                  <p className="text-red-500 text-xs italic">
                    {errors.mobileNumber}
                  </p>
                )}
                <p className="text-gray-600 text-xs italic">
                  with country code{" "}
                </p>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-company-name"
                >
                  Country
                </label>
                <input
                  className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                    errors.country ? "border-red-500" : "border-gray-200"
                  } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                  id="grid-company-name"
                  type="text"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                />
                {errors.country && (
                  <p className="text-red-500 text-xs italic">
                    {errors.country}
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-company-name"
                >
                  Product
                </label>
                <select
                  className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                    errors.product ? "border-red-500" : "border-gray-200"
                  } rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white`}
                  id="grid-product"
                  name="product"
                  placeholder="Product"
                  value={formData.product}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  {ProductList.map((product) => (
                    <option key={product.title} value={product.title}>
                      {product.title}
                    </option>
                  ))}
                </select>
                {errors.product && (
                  <p className="text-red-500 text-xs italic">
                    {errors.product}
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-message"
                >
                  Message
                </label>
                <textarea
                  id="grid-message"
                  name="message"
                  className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${
                    errors.message ? "border-red-500" : "border-gray-200"
                  } rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
                {errors.message && (
                  <p className="text-red-500 text-xs italic">
                    {errors.message}
                  </p>
                )}
                <p className="text-gray-600 text-xs italic">
                  Make it as long and as crazy as you'd like
                </p>
              </div>
            </div>
            <button
              type="submit"
              className="py-3 px-5 text-sm font-medium text-center rounded-lg bg-green-300 sm:w-fit hover:bg-green-400 focus:ring-4 focus:outline-none focus:ring-green-200"
            >
              Send message
            </button>
            {Object.keys(errors).length > 0 && (
              <p className="text-red-500 text-xs italic py-2">
                Errors in form. Please correct them.
              </p>
            )}
          </form>
        </div>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15123.49319996635!2d73.7278977!3d18.6247677!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bb69f737d421%3A0xb3afef6b6b5b2b8a!2sAdiamville%20Society!5e0!3m2!1sen!2sin!4v1724000606102!5m2!1sen!2sin"
        className="w-full"
        height="350"
        style={{ border: "0" }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </>
  );
};

export default Contact;
