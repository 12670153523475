import banana from "../images/bananaProduct.jpg";
import vermiProduct from "../images/vermiProduct.jpg";
import frozen from "../images/ChickenProduct.jpg";
import React from "react";
import { Link } from "react-router-dom";
import egg from "../images/EggProduct2.jpg";
import { ProductList } from "../config";

const imageMap = {
  banana,
  vermiProduct,
  egg,
  frozen,
};

const Products = () => {
  return (
    <div className="mx-auto p-4 md:p-8">
      <div className="w-full md:w-3/4 mx-auto">
        {ProductList.map((product, index) => (
          <div
            key={index}
            className="my-6 md:my-10 border bg-gray-100 rounded-xl overflow-hidden"
          >
            <div className="relative group h-48 md:h-60 lg:h-80 overflow-hidden">
              <img
                src={imageMap[product.image]}
                alt={product.title}
                className="w-full h-full object-cover"
              />
              {product.title && (
                <div className="absolute inset-0 bg-blue-500 bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                  <Link to={product.productRoute}>
                    <span className="text-white font-bold text-lg md:text-xl">
                      {product.title}
                    </span>
                  </Link>
                </div>
              )}
            </div>
            <div className="text-gray-500 text-2xl md:text-4xl lg:text-5xl p-2 md:p-4 m-3 leading-10 text-center">
              <div className="bg-gray-lines capitalize">{product.title}</div>
              <div className="flex justify-center mt-4">
                <button className="bg-blue-500 text-sm md:text-xl text-white p-2 md:p-3 rounded-md">
                  <Link to={product.productRoute}>More details ➣➣</Link>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Products;
